import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import Context, { I18nContextProps } from './Context';

// all currencies:
// Intl.supportedValuesOf("currency")
const COUNTRY_CURRENCY_MAP = {
  AD: 'EUR',
  AE: 'AED',
  AF: 'AFN',
  AG: 'XCD',
  AI: 'XCD',
  AL: 'ALL',
  AM: 'AMD',
  AO: 'AOA',
  AR: 'ARS',
  AS: 'USD',
  AT: 'EUR',
  AU: 'AUD',
  AW: 'AWG',
  AX: 'EUR',
  AZ: 'AZN',
  BA: 'BAM',
  BB: 'BBD',
  BD: 'BDT',
  BE: 'EUR',
  BF: 'XOF',
  BG: 'BGN',
  BH: 'BHD',
  BI: 'BIF',
  BJ: 'XOF',
  BL: 'EUR',
  BM: 'BMD',
  BN: 'BND',
  BO: 'BOB',
  BQ: 'USD',
  BR: 'BRL',
  BS: 'BSD',
  BT: 'BTN',
  BV: 'NOK',
  BW: 'BWP',
  BY: 'BYN',
  BZ: 'BZD',
  CA: 'CAD',
  CC: 'AUD',
  CD: 'CDF',
  CF: 'XAF',
  CG: 'XAF',
  CH: 'CHF',
  CI: 'XOF',
  CK: 'NZD',
  CL: 'CLP',
  CM: 'XAF',
  CN: 'CNY',
  CO: 'COP',
  CR: 'CRC',
  CU: 'CUP',
  CV: 'CVE',
  CW: 'ANG',
  CX: 'AUD',
  CY: 'EUR',
  CZ: 'CZK',
  DE: 'EUR',
  DJ: 'DJF',
  DK: 'DKK',
  DM: 'XCD',
  DO: 'DOP',
  DZ: 'DZD',
  EC: 'USD',
  EE: 'EUR',
  EG: 'EGP',
  EH: 'MAD',
  ER: 'ERN',
  ES: 'EUR',
  ET: 'ETB',
  FI: 'EUR',
  FJ: 'FJD',
  FK: 'FKP',
  FM: 'USD',
  FO: 'DKK',
  FR: 'EUR',
  GA: 'XAF',
  GB: 'GBP',
  GD: 'XCD',
  GE: 'GEL',
  GF: 'EUR',
  GG: 'GBP',
  GH: 'GHS',
  GI: 'GIP',
  GL: 'DKK',
  GM: 'GMD',
  GN: 'GNF',
  GP: 'EUR',
  GQ: 'XAF',
  GR: 'EUR',
  GS: 'GBP',
  GT: 'GTQ',
  GU: 'USD',
  GW: 'XOF',
  GY: 'GYD',
  HK: 'HKD',
  HM: 'AUD',
  HN: 'HNL',
  HR: 'EUR',
  HT: 'HTG',
  HU: 'HUF',
  ID: 'IDR',
  IE: 'EUR',
  IL: 'ILS',
  IM: 'GBP',
  IN: 'INR',
  IO: 'USD',
  IQ: 'IQD',
  IR: 'IRR',
  IS: 'ISK',
  IT: 'EUR',
  JE: 'GBP',
  JM: 'JMD',
  JO: 'JOD',
  JP: 'JPY',
  KE: 'KES',
  KG: 'KGS',
  KH: 'KHR',
  KI: 'AUD',
  KM: 'KMF',
  KN: 'XCD',
  KP: 'KPW',
  KR: 'KRW',
  KW: 'KWD',
  KY: 'KYD',
  KZ: 'KZT',
  LA: 'LAK',
  LB: 'LBP',
  LC: 'XCD',
  LI: 'CHF',
  LK: 'LKR',
  LR: 'LRD',
  LS: 'LSL',
  LT: 'EUR',
  LU: 'EUR',
  LV: 'EUR',
  LY: 'LYD',
  MA: 'MAD',
  MC: 'EUR',
  MD: 'MDL',
  ME: 'EUR',
  MF: 'EUR',
  MG: 'MGA',
  MH: 'USD',
  MK: 'MKD',
  ML: 'XOF',
  MM: 'MMK',
  MN: 'MNT',
  MO: 'MOP',
  MP: 'USD',
  MQ: 'EUR',
  MR: 'MRO',
  MS: 'XCD',
  MT: 'EUR',
  MU: 'MUR',
  MV: 'MVR',
  MW: 'MWK',
  MX: 'MXN',
  MY: 'MYR',
  MZ: 'MZN',
  NA: 'NAD',
  NC: 'XPF',
  NE: 'XOF',
  NF: 'AUD',
  NG: 'NGN',
  NI: 'NIO',
  NL: 'EUR',
  NO: 'NOK',
  NP: 'NPR',
  NR: 'AUD',
  NU: 'NZD',
  NZ: 'NZD',
  OM: 'OMR',
  PA: 'PAB',
  PE: 'PEN',
  PF: 'XPF',
  PG: 'PGK',
  PH: 'PHP',
  PK: 'PKR',
  PL: 'PLN',
  PM: 'EUR',
  PN: 'NZD',
  PR: 'USD',
  PS: 'ILS',
  PT: 'EUR',
  PW: 'USD',
  PY: 'PYG',
  QA: 'QAR',
  RE: 'EUR',
  RO: 'RON',
  RS: 'RSD',
  RU: 'RUB',
  RW: 'RWF',
  SA: 'SAR',
  SB: 'SBD',
  SC: 'SCR',
  SD: 'SDG',
  SE: 'SEK',
  SG: 'SGD',
  SH: 'SHP',
  SI: 'EUR',
  SJ: 'NOK',
  SK: 'EUR',
  SL: 'SLL',
  SM: 'EUR',
  SN: 'XOF',
  SO: 'SOS',
  SR: 'SRD',
  ST: 'STD',
  SV: 'SVC',
  SX: 'ANG',
  SY: 'SYP',
  SZ: 'SZL',
  TC: 'USD',
  TD: 'XAF',
  TF: 'EUR',
  TG: 'XOF',
  TH: 'THB',
  TJ: 'TJS',
  TK: 'NZD',
  TL: 'USD',
  TM: 'TMT',
  TN: 'TND',
  TO: 'TOP',
  TR: 'TRY',
  TT: 'TTD',
  TV: 'AUD',
  TW: 'TWD',
  TZ: 'TZS',
  UA: 'UAH',
  UG: 'UGX',
  UM: 'USD',
  US: 'USD',
  UY: 'UYU',
  UZ: 'UZS',
  VA: 'EUR',
  VC: 'XCD',
  VE: 'VEF',
  VG: 'USD',
  VI: 'USD',
  VN: 'VND',
  VU: 'VUV',
  WF: 'XPF',
  WS: 'WST',
  YE: 'YER',
  YT: 'EUR',
  ZA: 'ZAR',
  ZM: 'ZMW',
  ZW: 'ZWL'
};
const USD = 'USD';
const defaultCurrencyOptions = ['USD', 'GBP', 'EUR'];

// TODO: move to a more general helpers file
function isFiniteNumber(n: unknown): n is number {
  return Number.isFinite(n);
}

// TODO: move to a more general helpers file
export function asFloat(n: number | string): number {
  if (isFiniteNumber(n)) return n;

  return parseFloat(n);
}

export function asMoneyHelper(
  n: number | string,
  showFractional = true,
  fractionDigits = 2,
  currency = USD
) {
  if (n === undefined || n === null || Number.isNaN(n)) return '';

  const digits = showFractional ? fractionDigits : 0;

  return asFloat(n).toLocaleString(undefined, {
    style: 'currency',
    currency,
    maximumFractionDigits: digits,
    minimumFractionDigits: digits
  });
}

export function asMoneyReducedHelper(
  n: number | string,
  fractionDigits = 1,
  currency = USD
) {
  const num = asFloat(n);
  const { limit, letter } =
    COUNT_FORMATS.find(({ limit: l }) => num < l) ?? COUNT_FORMATS[0];

  const value = (1000 * num) / limit;

  return asMoneyHelper(value, true, fractionDigits, currency) + letter;
}

export function asMoneyDiffHelper(
  n: number | string,
  showFractional = true,
  fractionDigits = 2,
  currency = USD
) {
  const money = asMoneyHelper(n, showFractional, fractionDigits, currency);

  if (asFloat(n) < 0) return money;

  return `+${money}`;
}

function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

type ProviderProps = {
  children: ReactNode;
  defaultCurrency?: string;
};
const Provider: FC<ProviderProps> = ({
  children,
  defaultCurrency = defaultCurrencyOptions[0]
}) => {
  const [currencyOptions, setCurrencyOptions] = useState<string[]>(
    Array.from(
      new Set([...defaultCurrencyOptions, defaultCurrency].filter(isDefined))
    )
  );
  const [currency, setCurrency] = useState(defaultCurrency);
  const [currencySymbol, setCurrencySymbol] = useState('');

  const asMoney = useCallback<I18nContextProps['asMoney']>(
    (n, showFractional = true, fractionDigits = 2) =>
      asMoneyHelper(n, showFractional, fractionDigits, currency),
    [currency]
  );

  const asMoneyDiff = useCallback<I18nContextProps['asMoneyDiff']>(
    (n, showFractional = true, fractionDigits = 2) =>
      asMoneyDiffHelper(n, showFractional, fractionDigits, currency),
    [currency]
  );

  const asMoneyReduced = useCallback<I18nContextProps['asMoneyReduced']>(
    (n, fractionDigits = 1) =>
      asMoneyReducedHelper(n, fractionDigits, currency),
    [currency]
  );

  const handleSetCurrencyOptions = useCallback(
    (options?: string[]) => {
      const updatedOptions = options?.length ? options : defaultCurrencyOptions;
      const optionsSet = new Set(updatedOptions);

      // should we always include USD here?
      // if (!optionsSet.has(USD)) updatedOptions.push(USD);

      setCurrencyOptions(updatedOptions);

      if (optionsSet.has(currency)) return;

      setCurrency(updatedOptions[0] ?? USD); // not sure why [0] would ever be null
    },
    [currency]
  );

  const initialProviderValue: I18nContextProps = useMemo(
    () => ({
      currency,
      setCurrency,
      currencyOptions,
      setCurrencyOptions: handleSetCurrencyOptions,
      currencySymbol,

      asMoney,
      asMoneyDiff,
      asMoneyReduced
    }),
    [
      currency,
      currencyOptions,
      currencySymbol,
      handleSetCurrencyOptions,
      asMoney,
      asMoneyDiff,
      asMoneyReduced
    ]
  );

  useEffect(() => {
    const locale = navigator?.languages?.[0] ?? navigator?.language;
    const country = locale?.split('-')[1]?.toUpperCase();
    const browserCurrency =
      COUNTRY_CURRENCY_MAP[country as keyof typeof COUNTRY_CURRENCY_MAP] ??
      defaultCurrency;

    const formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: browserCurrency
    });
    const userCurrency = formatter.resolvedOptions().currency;

    const symbol = formatter
      .formatToParts(1)
      .find((x) => x.type === 'currency')?.value;
    if (symbol) setCurrencySymbol(symbol);

    // Set the currency if it's in the options
    if (!userCurrency) return;

    if (!currencyOptions.includes(userCurrency)) {
      setCurrencyOptions((prev) => [userCurrency, ...prev]);
    }
    setCurrency(userCurrency);
  }, [currencyOptions, defaultCurrency]); // Dependency array includes currencyOptions

  return (
    <Context.Provider value={initialProviderValue}>{children}</Context.Provider>
  );
};

export default Provider;

// Configuration
const COUNT_FORMATS = [
  {
    // 0 - 999
    letter: '',
    limit: 1e3
  },
  {
    // 1,000 - 999,999
    letter: 'K',
    limit: 1e6
  },
  {
    // 1,000,000 - 999,999,999
    letter: 'M',
    limit: 1e9
  },
  {
    // 1,000,000,000 - 999,999,999,999
    letter: 'B',
    limit: 1e12
  },
  {
    // 1,000,000,000,000 - 999,999,999,999,999
    letter: 'T',
    limit: 1e15
  }
];
